import {
  useCallback,
  useMemo,
  createContext,
  ReactNode,
  useContext,
} from 'react'
import { InquiryError } from 'persona/dist/lib/interfaces'
import useSettings from './useSettings'
import { State } from './usePageAnalytics'

// Outgoing Messages (events)
export type MessageOut =
  | 'LOAD'
  | 'EXIT'
  | 'SESSION_EXPIRED'
  | 'BETA_CONTRIBUTION_SELECTED'
  | 'BETA_WITHDRAWAL_SELECTED'
  | 'ONBOARDING_SUCCESS'
  | AnalyticsMessage
  | IdentityMessage
  | RedirectMessage

export type IdentityMessage =
  | IdentityMessageComplete
  | IdentityMessageCancel
  | IdentityMessageError

export type AnalyticsMessage = {
  type: 'ANALYTICS'
  subtype: 'ONBOARDING_SUCCESS' | 'ONBOARDING_DROPPED'
  payload: State
}

export type RedirectMessage = {
  type: 'REDIRECT'
  url: string
}

export type IdentityMessageComplete = {
  type: 'identity_complete'
  verification: 'success' | 'fail'
}

export type IdentityMessageCancel = {
  type: 'identity_cancel'
}

export type IdentityMessageError = {
  type: 'identity_error'
  details: InquiryError
}

// Incoming Messages
export type MessageIn = OneTimeTokenMessage | HistoryBackMessage
export type MessageInName = 'ONE_TIME_TOKEN' | 'history_back'
export type OneTimeTokenMessage = {
  type: 'ONE_TIME_TOKEN'
  token: string
}
export type HistoryBackMessage = {
  type: 'history_back'
}
export type UnlistenFn = () => void

export type MessageContextType = {
  send: (message: MessageOut) => void
  exit: () => void
  addListener: <T>(
    eventType: MessageInName,
    listener: (message: T) => void
  ) => UnlistenFn
}

const MessageContext = createContext<MessageContextType>({
  send: () => null,
  exit: () => null,
  addListener: () =>
    function () {
      return null
    },
})

/**
 * Send a message to host application
 *
 * Always provide a specific targetOrigin, not *,
 * if you know where the other window's document should be located.
 * Failing to provide a specific target discloses the data you send to any interested malicious site.
 *
 * See `Security Concerns` on MDN for details
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage#security_concerns
 */
export default function usePostMessageChannel() {
  return useContext(MessageContext)
}

export function isIOSWebView() {
  return /\(ip.*applewebkit(?!.*(version|crios))/i.test(navigator.userAgent)
}

export function isReactNativeWebView() {
  return typeof window.ReactNativeWebView?.postMessage === 'function'
}

export function PostMessageProvider(props: { children: ReactNode }) {
  const settings = useSettings()
  const targetOrigin = settings.origin
  const isIOSApp = useMemo(() => isIOSWebView(), [])
  const isReactNativeApp = useMemo(() => isReactNativeWebView(), [])
  const receiver = useMemo(() => {
    if (isReactNativeApp) {
      if (!window.ReactNativeWebView) {
        console.warn(
          "Unexpected empty window.ReactNativeWebView. Please check your module setup. Can't send events to host application."
        )
        return null
      } else {
        console.log('usePostMessageChannel:[react-native]')
        return window.ReactNativeWebView
      }
    }

    if (isIOSApp) {
      if (!window.webkit.messageHandlers.iosListener) {
        console.warn(
          "Unexpected empty window.webkit.messageHandlers.iosListener. Please check your module setup. Can't send events to host application."
        )
        return null
      } else {
        console.log('usePostMessageChannel:[ios]')
        return window.webkit.messageHandlers.iosListener
      }
    }

    console.log('usePostMessageChannel:[web]')
    return window.parent
  }, [isReactNativeApp, isIOSApp])

  if (!isReactNativeApp) {
    try {
      new URL(targetOrigin)
    } catch (error) {
      throw new Error(
        'targetOrigin is required for using postMessage channel, received ' +
          JSON.stringify(targetOrigin)
      )
    }
  }

  const send = useCallback(
    (message: MessageOut) => {
      if (isReactNativeApp) {
        receiver?.postMessage(message)
      } else {
        receiver?.postMessage(message, targetOrigin)
      }
    },
    [receiver, targetOrigin, isReactNativeApp]
  )
  const exit = useCallback(() => send('EXIT'), [send])
  const addListener = useCallback(
    (eventType, listener) => {
      const handler = (event: any) => {
        if (event.origin === targetOrigin) {
          if (event.data.type === eventType) {
            listener(event.data)
          }
        }
      }
      window.addEventListener('message', handler, false)
      return () => window.removeEventListener('message', handler, false)
    },
    [targetOrigin]
  )

  const value = useMemo<MessageContextType>(
    () => ({ send, exit, addListener }),
    [send, exit, addListener]
  )

  return (
    <MessageContext.Provider value={value}>
      {props.children}
    </MessageContext.Provider>
  )
}

import localStorageHelper from './localStorageHelper'

export interface Entity {
  id: string
  type: EntityType
  toDocSearchParams(): URLSearchParams
  isOnboardingFinished(): boolean
}

export async function fetchEntity(
  base: string,
  enabledOnboardingConfirmErrors: boolean,
  data: {
    sessionToken: string
    userId?: string
    corporateId?: string
    participantId?: string
  }
) {
  if (data.userId) {
    const [verifications, accounts] = await Promise.all([
      fetchVerifications(data.userId, base, data.sessionToken),
      fetchAccounts(data.userId, base, data.sessionToken),
    ])
    const personaInquiryStarted = localStorageHelper.get(
      'personaInquiryStarted'
    )
    return new UserEntity(
      data.userId,
      verifications,
      accounts,
      enabledOnboardingConfirmErrors,
      personaInquiryStarted
    )
  }

  if (data.corporateId) {
    return new CorporateEntity(data.corporateId)
  }

  if (data.participantId) {
    const url = new URL(`/participants/${data.participantId}`, base).toString()
    const rsp = await fetch(url, {
      headers: {
        'Atomic-Session': data.sessionToken,
      },
    })
    const participant: Atomic.Participant = await rsp.json()
    return new ParticipantEntity(data.participantId, participant.account_id)
  }

  throw new Error('Unknown entity type')
}

async function fetchVerifications(
  userId: string,
  base: string,
  sessionToken: string,
  nextCursor?: string
) {
  const url = new URL('/verifications', base)
  url.searchParams.append('user_id', userId)
  if (nextCursor) {
    url.searchParams.append('cursor', nextCursor)
  }
  const rsp = await fetch(url.toString(), {
    headers: {
      'Atomic-Session': sessionToken,
    },
  })
  if (!rsp.ok) {
    throw new Error('Unable to fetch verifications for user ' + userId)
  }
  const data = await rsp.json()
  const verifications: Atomic.Verification[] = data.elements ?? []
  if (data.metadata?.next_cursor) {
    const nextPage: Atomic.Verification[] = await fetchVerifications(
      userId,
      base,
      sessionToken,
      data.metadata.next_cursor
    )
    return verifications.concat(nextPage)
  } else {
    return verifications
  }
}

async function fetchAccounts(
  userId: string,
  base: string,
  sessionToken: string,
  nextCursor?: string
) {
  const url = new URL('/accounts', base)
  url.searchParams.append('user_id', userId)
  if (nextCursor) {
    url.searchParams.append('cursor', nextCursor)
  }
  const rsp = await fetch(url.toString(), {
    headers: {
      'Atomic-Session': sessionToken,
    },
  })
  if (!rsp.ok) {
    throw new Error('Unable to fetch accounts for user ' + userId)
  }
  const data = await rsp.json()
  const accounts: Atomic.Account[] = data.elements ?? []
  if (data.metadata?.next_cursor) {
    const nextPage: Atomic.Account[] = await fetchAccounts(
      userId,
      base,
      sessionToken,
      data.metadata.next_cursor
    )
    return accounts.concat(nextPage)
  } else {
    return accounts
  }
}

export class UserEntity implements Entity {
  readonly id
  readonly type = 'USER'
  readonly verifications
  readonly accounts
  readonly enabledOnboardingConfirmErrors
  readonly personaInquiry

  constructor(
    id: string,
    verifications: Atomic.Verification[],
    accounts: Atomic.Account[],
    enabledOnboardingConfirmErrors: boolean,
    personaInquiry?: string
  ) {
    this.id = id
    this.verifications = verifications
    this.accounts = accounts
    this.enabledOnboardingConfirmErrors = enabledOnboardingConfirmErrors
    this.personaInquiry = personaInquiry
  }

  toDocSearchParams() {
    const params = new URLSearchParams()
    params.append('user_id', this.id)
    return params
  }

  isOnboardingFinished() {
    // Check persona inquiry
    const personaInquiryStarted = this.personaInquiry === this.id
    if (personaInquiryStarted) {
      return true
    }

    // Some older users may have empty verifications.
    // For these users we look for approved account.
    const hasApprovedAccount = this.accounts.some(
      (a) => a.account_status === 'APPROVED'
    )
    if (hasApprovedAccount) {
      return true
    }

    // It's weird, but we consider 'processing' as successful KYC check
    // User can still fail verification after (due to Pershing check), but it's rare event
    const isVerified = this.verifications.some(
      (v) => v.status === 'processing' || v.status === 'successful'
    )
    if (isVerified) {
      return true
    }

    // By default we don't wait for verification to be successful.
    // It's enough to check that we started the process. The rest will be handled by dashboard.
    // For partners that use only onboarding, it's better to enable 'dev-enable-onboarding-confirm-errors'
    const hasStartedVerification = this.verifications.length > 0
    if (!this.enabledOnboardingConfirmErrors && hasStartedVerification) {
      return true
    }

    return false
  }
}

class ParticipantEntity implements Entity {
  readonly id
  readonly type = 'PARTICIPANT'
  readonly accountId

  constructor(id: string, accountId: string) {
    this.id = id
    this.accountId = accountId
  }

  toDocSearchParams() {
    const params = new URLSearchParams()
    params.append('account_id', this.accountId)
    return params
  }

  isOnboardingFinished() {
    return false
  }
}

class CorporateEntity implements Entity {
  readonly id
  readonly type = 'CORPORATE'

  constructor(id: string) {
    this.id = id
  }

  toDocSearchParams() {
    const params = new URLSearchParams()
    params.append('corporate_id', this.id)
    return params
  }

  isOnboardingFinished() {
    return false
  }
}

import { z } from 'zod'

const ThemeFont = z.object({
  'family-primary': z.string(),
  'weight-primary-regular': z.string(),
  'weight-primary-bold': z.string(),
})

const ThemeColors = z.object({
  // Neutral
  neutral0: z.string(),
  neutral5: z.string(),
  neutral10: z.string(),
  neutral25: z.string(),
  neutral50: z.string(),
  neutral75: z.string(),
  neutral100: z.string(),
  neutral150: z.string(),
  neutral200: z.string(),

  // Blue
  primary5: z.string(),
  primary10: z.string(),
  primary25: z.string(),
  primary40: z.string(),
  primary50: z.string(),
  primary75: z.string(),
  primary100: z.string(),
  primary150: z.string(),
  primary200: z.string(),

  // Red
  secondary5: z.string(),
  secondary10: z.string(),
  secondary25: z.string(),
  secondary40: z.string(),
  secondary50: z.string(),
  secondary75: z.string(),
  secondary100: z.string(),
  secondary150: z.string(),
  secondary200: z.string(),

  // Yellow
  warning5: z.string(),
  warning10: z.string(),
  warning25: z.string(),
  warning40: z.string(),
  warning50: z.string(),
  warning75: z.string(),
  warning100: z.string(),
  warning150: z.string(),
  warning200: z.string(),

  // Green
  success5: z.string(),
  success10: z.string(),
  success25: z.string(),
  success40: z.string(),
  success50: z.string(),
  success75: z.string(),
  success100: z.string(),
  success150: z.string(),
  success200: z.string(),
})

export const ThemeSchema = z
  .object({
    font: ThemeFont.partial(),
    colors: ThemeColors.partial(),
  })
  .partial()

import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import * as FullStory from '@fullstory/browser'
import * as Sentry from '@sentry/browser'
import useSettings from './useSettings'
import usePostMessageChannel, {
  OneTimeTokenMessage,
} from './usePostMessageChannel'
import createBaseURL from './createBaseURL'
import localStorageHelper from './localStorageHelper'
import merge from 'lodash/merge'
import useFeature from './useFeature'
import ErrorPage from '../components/ErrorPage'
import { DefaultThemeProvider } from './useTheme'
import isObject from 'lodash/isObject'
import { Entity, fetchEntity } from './entity'

export type SessionType = 'onboarding' | 'dashboard'

export interface Session {
  sessionToken: string
  sessionType: SessionType
  identifier: string
  userId: string
  customTheme: any
  customText: any
  entity: Entity
  base: string
}

type Status = 'not-started' | 'pending' | 'started' | 'error'
type RefreshSession = () => Promise<unknown>
type UpgradeSession = (sessionType: SessionType) => Promise<unknown>

const SessionContext = createContext<Session | undefined>(undefined)

const UpgradeSessionContext = createContext<UpgradeSession>(() =>
  Promise.resolve(null)
)

const RefreshSessionContext = createContext<RefreshSession>(() =>
  Promise.resolve(null)
)

export default function useSession(): Session {
  const context = useContext(SessionContext)
  if (!context) {
    throw new Error('SessionContext is not defined')
  }
  return context
}

export function useSessionUpgrade() {
  return useContext(UpgradeSessionContext)
}

export function useSessionRefresh() {
  return useContext(RefreshSessionContext)
}

interface SessionProviderProps {
  children: ReactNode
}

export function SessionProvider(props: SessionProviderProps) {
  const settings = useSettings()
  const postMessage = usePostMessageChannel()
  const enabledOnBoardingConfirmErrors = useFeature(
    'dev-enable-onboarding-confirm-errors'
  )
  const base = createBaseURL(
    settings.mode,
    settings.cluster,
    settings._override_api
  )
  const { addListener, send } = usePostMessageChannel()
  const [token, setToken] = useState(settings.token)
  const [status, setStatus] = useState<Status>('not-started')
  const [error, setError] = useState<Error>()
  const [session, setSession] = useState<Session>()

  const refreshSession = useCallback(() => {
    if (status !== 'pending') {
      setStatus('pending')
      console.log('session expired')
      send('SESSION_EXPIRED')

      return new Promise((resolve) => {
        const removeListener = addListener<OneTimeTokenMessage>(
          'ONE_TIME_TOKEN',
          (message) => {
            console.log('receiving new oneTimeToken')
            setToken(message.token)
            removeListener()
            resolve(null)
          }
        )
      })
    }

    return new Promise((resolve) => {
      const removeListener = addListener('ONE_TIME_TOKEN', () => {
        removeListener()
        resolve(null)
      })
    })
  }, [status, send, addListener])

  const upgradeSession = useCallback(
    async (sessionType) => {
      if (session) {
        setSession({
          sessionToken: session.sessionToken,
          sessionType,
          userId: session.userId,
          identifier: session.identifier,
          customTheme: session.customTheme,
          customText: session.customText,
          entity: session.entity,
          base,
        })
      } else {
        return Promise.resolve()
      }
    },
    [base, session]
  )

  const startSession = useCallback(
    async (
      sessionData: SessionTokensResponse,
      base: string,
      options: {
        cluster: Cluster
        token?: string | SessionAuth
        theme?: Theme | string
        custom_css?: CustomCssOption
        text?: string
      }
    ): Promise<Session> => {
      includeCustomCSS(
        sessionData,
        options.cluster ?? 'prod',
        options.custom_css
      )

      const entity = await fetchEntity(
        base,
        enabledOnBoardingConfirmErrors,
        sessionData
      )
      const values = await Promise.all([
        sessionData,
        ...(options.theme === 'custom'
          ? [fetchCustomTheme(sessionData, options.cluster)]
          : []),
        ...(options.text === 'custom'
          ? [fetchCustomText(sessionData, options.cluster)]
          : []),
      ])

      let data = merge(...values)

      return {
        sessionToken: data.sessionToken,
        sessionType: entity.isOnboardingFinished() ? 'dashboard' : 'onboarding',
        userId: data.userId,
        identifier: data.identifier,
        customTheme: data.customTheme,
        customText: data.customText,
        entity,
        base,
      }
    },
    [enabledOnBoardingConfirmErrors]
  )

  const setupFullStory = useCallback(
    (session: Session) => {
      if (process.env.REACT_APP_FULLSTORY_ORG_ID) {
        try {
          FullStory.identify(session.entity.id, {
            identifier_str: session.identifier,
          })
          FullStory.event('session', {
            'one-time-token': token,
          })
        } catch (err) {
          console.warn('Failed to identify user (FullStory)')
        }
      }
      return session
    },
    [token]
  )

  const setupSentry = useCallback((session: Session) => {
    if (process.env.REACT_APP_SENTRY_DSN) {
      try {
        Sentry.configureScope((scope) => {
          scope.setTag('tenant', session.identifier)
          scope.setTag('entity_type', session.entity.type)
          scope.setUser({
            id: session.entity.id,
          })

          // Mark sessions that uses an old way to handle OAuth redirects.
          // Old way: redirect is happening inside webview
          // New way: redirect is opening in default browser
          // We should get to the point when everybody uses the new way, and `plaid_unexpected_redirect` is always `false`
          // Related: https://atomic-invest.atlassian.net/browse/AT-2204
          const oauthStateId = window.location.href.includes('?oauth_state_id=')
          scope.setTag('plaid_unexpected_redirect', Boolean(oauthStateId))

          try {
            // https://help.fullstory.com/hc/en-us/articles/360020828073
            scope.setContext('FullStory', {
              fullStoryUrl: FullStory.getCurrentSessionURL(true),
            })
          } catch (error) {
            // ignore
          }
        })
      } catch (err) {
        console.warn('Failed to setup scope (Sentry)')
      }
    }
    return session
  }, [])

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>
    if (settings.custom_css === 'staging') {
      interval = setInterval(() => {
        const link = document.querySelector('#custom-css') as HTMLLinkElement
        const newLink = document.createElement('link')
        newLink.href = link.href
        newLink.id = 'custom-css'
        newLink.rel = 'stylesheet'
        newLink.type = 'text/css'
        newLink.onload = () => document.body.removeChild(link)
        document.body.appendChild(newLink)
        console.log('update custom css')
      }, 2000)
    }
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [settings.custom_css])

  useEffect(() => {
    exchangeToken(base, settings)
      .then((sessionData) =>
        startSession(sessionData, base, {
          token: settings.token,
          theme: settings.theme,
          text: settings.text,
          custom_css: settings.custom_css,
          cluster: settings.cluster,
        })
      )
      .then(setupFullStory)
      .then(setupSentry)
      .then((session) => {
        setSession(session)
        setStatus('started')
      })
      .catch((error) => {
        setStatus('error')
        setError(error)
      })
  }, [settings, base, startSession, setupFullStory, setupSentry])

  return (
    <UpgradeSessionContext.Provider value={upgradeSession}>
      <RefreshSessionContext.Provider value={refreshSession}>
        <SessionContext.Provider value={session}>
          {(status === 'started' || status === 'pending') && props.children}
          {status === 'error' && error && (
            <DefaultThemeProvider>
              <ErrorPage error={error} onExit={postMessage.exit} />
            </DefaultThemeProvider>
          )}
        </SessionContext.Provider>
      </RefreshSessionContext.Provider>
    </UpgradeSessionContext.Provider>
  )
}

type SessionTokensResponse = {
  userId: string
  corporateId?: string
  sessionToken: string
  identifier: string
}

async function exchangeToken(base: string, config: Settings) {
  // restore from localStorage
  const savedSession = localStorageHelper.get(
    'session'
  ) as SessionTokensResponse
  if (savedSession) {
    localStorageHelper.clear('session')
    return Promise.resolve(savedSession)
  }

  // use session token
  if (isObject(config.token)) {
    switch (config.token.entity_type) {
      case 'user':
        return {
          sessionToken: config.token.session_token,
          identifier: config.token.identifier,
          userId: config.token.entity_id,
        } as SessionTokensResponse

      case 'corporate':
        return {
          sessionToken: config.token.session_token,
          identifier: config.token.identifier,
          corporateId: config.token.entity_id,
        } as SessionTokensResponse
    }
  }

  // start session using one-time token
  const url = new URL('/auth/session-tokens', base)
  return fetch(url.toString(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: config.token,
    }),
  })
    .then((rsp) => {
      if (rsp.ok) {
        return rsp.json()
      }

      throw new Error('Unable to start session')
    })
    .then((data) => ({
      userId: data.userId ?? data.user_id,
      corporateId: data.corporateId ?? data.corporate_id,
      participantId: data.participantId ?? data.participant_id,
      sessionToken: data.session_token,
      identifier: data.identifier,
    }))
}

async function fetchCustomTheme<T extends { identifier?: string }>(
  data: T,
  cluster: Cluster
): Promise<T> {
  const domain =
    cluster === 'prod'
      ? 'https://module.cdn.atomicvest.com'
      : 'https://module.cdn.qa.atomicvest.com'
  const env = 'production' // for now just production version
  const rsp = await fetch(
    `${domain}/custom-theme/${env}/${data.identifier}.json`
  )

  if (!rsp.ok) {
    return data
  }

  const customTheme = await rsp.json()

  return {
    ...data,
    customTheme,
  }
}

async function fetchCustomText<T extends { identifier?: string }>(
  data: T,
  cluster: Cluster
): Promise<T> {
  const domain =
    cluster === 'prod'
      ? 'https://module.cdn.atomicvest.com'
      : 'https://module.cdn.qa.atomicvest.com'
  const env = 'production' // for now just production version
  const rsp = await fetch(
    `${domain}/custom-text/${env}/${data.identifier}.json`
  )

  if (!rsp.ok) {
    return data
  }

  const customText = await rsp.json()

  return {
    ...data,
    customText,
  }
}

function includeCustomCSS<T extends { identifier?: string }>(
  data: T,
  cluster: Cluster,
  custom_css?: CustomCssOption
) {
  const link = document.querySelector('#custom-css') as HTMLLinkElement
  const domain =
    cluster === 'prod'
      ? 'https://module.cdn.atomicvest.com'
      : 'https://module.cdn.qa.atomicvest.com'
  if (link && custom_css) {
    if (custom_css === 'local') {
      link.href = `/custom-css.css`
    } else if (data.identifier) {
      link.href = `${domain}/custom-css/prod/${custom_css}/${data.identifier}.css`
    }
  }
  return data
}

import { createContext, ReactNode, useContext } from 'react'
import { ZodError, ZodIssue } from 'zod'
import { DefaultThemeProvider } from './useTheme'

const SettingsContext = createContext<any>(undefined)

export default function useSettings(): Settings {
  const context = useContext(SettingsContext)
  if (!context) {
    throw new Error('SettingsContext is not defined')
  }
  return context
}

interface SettingsProviderProps {
  value: Settings
  children: ReactNode
  error?: ZodError
}

export function SettingsProvider(props: SettingsProviderProps) {
  if (props.error) {
    return <InvalidConfigPage error={props.error} />
  }

  return (
    <SettingsContext.Provider value={props.value}>
      {props.children}
    </SettingsContext.Provider>
  )
}

function InvalidConfigPage(props: { error: ZodError }) {
  return (
    <DefaultThemeProvider>
      <div className="absolute h-screen w-screen bg-neutral0">
        <div className="mx-5 tbm:mx-10">
          <div className="mx-auto mt-10 max-w-[700px] rounded-md bg-warning25 p-4 tbm:p-10">
            <div className="tbm:text-h3">⚠️ Invalid module config</div>
            <div className="ml-6 mt-6 tbm:ml-10 tbm:mt-10">
              <div>
                The Atomic Module cannot be opened because the following
                validation failed:
                <ul className="ml-6 list-disc">
                  {props.error.issues.map((issue, i) => (
                    <li key={i}>{format(issue)}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DefaultThemeProvider>
  )
}

export function format(issue: ZodIssue) {
  return `${issue.message} (${issue.path.join('.')})`
}

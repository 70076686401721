import pick from 'lodash/pick'
import merge from 'lodash/merge'
import {
  createContext,
  ReactNode,
  useContext,
  useLayoutEffect,
  useMemo,
} from 'react'
import hexToRGB from './hexToRGB'
import useGoogleFont from './useGoogleFont'
import useSettings from './useSettings'
import useSession from './useSession'

const DEFAULT_THEME: Theme = {
  font: {
    'family-primary': 'atomic-default-font',
    'weight-primary-regular': '400',
    'weight-primary-bold': '500',
  },

  colors: {
    // Neutral
    neutral0: '#FFFFFF',
    neutral5: '#FDFDFD',
    neutral10: '#F5F6F7',
    neutral25: '#DBDDE2',
    neutral50: '#C2C6CF',
    neutral75: '#9298A8',
    neutral100: '#616A81',
    neutral150: '#303C5A',
    neutral200: '#0C1A3D',

    // Blue
    primary5: '#F5F9FF',
    primary10: '#E5F0FF',
    primary25: '#BDD9FF',
    primary40: '#82B8FF',
    primary50: '#5198F5',
    primary75: '#1676F5',
    primary100: '#005CD4',
    primary150: '#004197',
    primary200: '#00295F',

    // Red
    secondary5: '#FFF9F9',
    secondary10: '#FFE8EA',
    secondary25: '#FFC9CE',
    secondary40: '#FC9AA2',
    secondary50: '#F26874',
    secondary75: '#E03A48',
    secondary100: '#D10A2B',
    secondary150: '#94071F',
    secondary200: '#5E0513',

    // Yellow
    warning5: '#FFF8F0',
    warning10: '#FFEFD6',
    warning25: '#FFD9A3',
    warning40: '#F2B555',
    warning50: '#D98916',
    warning75: '#B56D00',
    warning100: '#995C00',
    warning150: '#6D4100',
    warning200: '#452900',

    // Green
    success5: '#F2FAF7',
    success10: '#DFF2E9',
    success25: '#AFE0C9',
    success40: '#73C9A1',
    success50: '#34A872',
    success75: '#1C8B57',
    success100: '#007A41',
    success150: '#00572E',
    success200: '#00371D',
  },
}

const ThemeContext = createContext<Theme>(DEFAULT_THEME)

export default function useTheme() {
  return useContext(ThemeContext)
}

export function ThemeProvider(props: { children: ReactNode }) {
  const settings = useSettings()
  const session = useSession()
  const theme: Theme = useMemo(() => {
    const themeConfig =
      settings.theme === 'custom'
        ? session.customTheme ?? {}
        : settings.theme ?? {}

    return merge(DEFAULT_THEME, pick(themeConfig, 'font', 'colors'))
  }, [settings, session.customTheme])
  useApplyTheme(theme)
  return (
    <ThemeContext.Provider value={theme}>
      {props.children}
    </ThemeContext.Provider>
  )
}

export function DefaultThemeProvider(props: { children: ReactNode }) {
  const theme: Theme = DEFAULT_THEME
  useApplyTheme(theme)
  return (
    <ThemeContext.Provider value={theme}>
      {props.children}
    </ThemeContext.Provider>
  )
}

function useApplyTheme(theme: Theme) {
  useLayoutEffect(() => {
    for (const key in theme.colors) {
      const value = hexToRGB(theme.colors[key]).join(', ')
      document.documentElement.style.setProperty(`--color-${key}`, value)
    }

    document.documentElement.style.setProperty(
      '--font-weight-primary-regular',
      theme.font['weight-primary-regular']
    )
    document.documentElement.style.setProperty(
      '--font-weight-primary-bold',
      theme.font['weight-primary-bold']
    )

    if (theme.font['family-primary']) {
      if (theme.font['family-primary'] === 'atomic-default-font') {
        document.documentElement.style.setProperty(
          `--font-family-primary`,
          '"Gotham A", "Gotham B", system-ui, -apple-system,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'
        )
      } else {
        document.documentElement.style.setProperty(
          `--font-family-primary`,
          theme.font['family-primary']
        )
      }
    }
  }, [theme])

  useGoogleFont(theme.font['family-primary'])
}
